.active {
  background-color: #1d4ed8;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #424143;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding-left: 20vh;
  padding-right: 20vh;
}

nav .title {
  font-size: 1.5rem;
  margin: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: white;
}

nav .title img {
  height: 6vh;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

nav ul li {
  list-style: none;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: white;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

nav ul li a:not(.active):hover {
  background-color: #4a4a4a;
}

nav .menu {
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 0.4rem;
  width: 100%;
  background-color: #fff;
  border-radius: 0.2rem;
}

.language-toggle {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.language-toggle img {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin: 0 10px;
}

.language-toggle img:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  nav {
    padding-left: 10vh;
    padding-right: 10vh;
  }
  nav .title img {
    height: 8vh; /* Orta boyutlu ekranlar için logo yüksekliğini artır */
  }
}

@media (max-width: 480px) {
  nav .menu {
    display: block;
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    width: 2.25rem;
    height: 2rem;
    border: none;
    cursor: pointer;
    z-index: 1001;
  }

  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5vh;
    padding-right: 5vh;
  }

  nav .title img {
    height: 6vh; /* Küçük boyutlu ekranlar için logo yüksekliğini artır */
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
    justify-content: center;
  }

  nav ul.open {
    display: flex;
  }

  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul li a {
    margin: 0.2rem 0.5rem;
  }

  .language-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
