.section__padding {
  padding: 4rem 8rem;
}

.footer {
  background-image: url("../../images/footer.svg");
  background-size: cover;
  position: relative;
  z-index: 1;
}
.footer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0.794) 100%
  );
  pointer-events: none;
  z-index: -1;
}

.sb__footer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.sb__footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
}

.sb__footer-links_div {
  width: 150px;
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
}
.sb__footer-links__div a {
  cursor: pointer;
}

a {
  color: rgb(175, 175, 179);
  text-decoration: none;
}

.socialmedia {
  display: flex;
  flex-direction: row;
}

.sb__footer-links_div h4 {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0.9rem;
}

.sb__footer-links_div p {
  font-size: 12px;
  line-height: 15px;
  margin: 0.5rem 0;
  cursor: pointer;
}

.sb__footer-below {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.2rem;
}

.sb__footer-below-links {
  display: flex;
  flex-direction: row;
}

.sb__footer-below-links p {
  font-size: 13px;
  line-height: 15px;
  margin-left: 2rem;
  color: rgb(0, 0, 0);
  font-weight: 600;
}

hr {
  color: rgb(29, 78, 216) !important;
  width: 100%;
}

.sb__footer-copyright p {
  font-size: 13px;
  line-height: 15px;
  color: rgb(0, 0, 0);
  font-weight: 600;
}

.footer-h4 {
  color: rgb(29, 78, 216);
}
.img-small {
  width: 2%;
  height: auto;
}

.contact-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.contact-info {
  display: flex;
  align-items: center;
}

.contact-icon {
  margin-right: 8px;
}

@media screen and (max-width: 850px) {
  .sb__footer-heading h1 {
    font-size: 44px;
    line-height: 50px;
  }
  .section__padding {
    padding: 4rem 4rem;
    font-size: small;
  }
  .sb__footer-links__div p::before {
    content: "\2022";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: rgb(29, 78, 216);
  }
  .sb__footer-links__div p {
    position: relative;
    padding-left: 20px;
  }
}

@media screen and (max-width: 550px) {
  .sb__footer-heading h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .sb__footer-links div {
    margin: 1rem 0;
  }
  .sb__footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }

  .sb__footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb__footer-below-links {
    flex-direction: column;
  }

  .sb__footer-below-links p {
    margin-left: 0;
    margin-top: 1rem;
  }
  .section__padding {
    padding: 4rem 4rem;
    font-size: small;
  }
  .sb__footer-links__div p::before {
    content: "\2022";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: rgb(29, 78, 216);
  }
  .sb__footer-links__div p {
    position: relative;
    padding-left: 20px;
  }
}

@media screen and (max-width: 400px) {
  .sb__footer-heading h1 {
    font-size: 27px;
    line-height: 38px;
  }
  .sb__footer-links {
    width: 29vh;
  }
  .section__padding {
    padding: 4rem 4rem;
    font-size: small;
  }
  .sb__footer-links__div p::before {
    content: "\2022";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: rgb(29, 78, 216);
  }
  .sb__footer-links__div p {
    position: relative;
    padding-left: 20px;
  }
}
