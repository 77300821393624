.class_whuys,
.class_about {
  background-image: url("../../../public/drone2.png");
  background-size: 20%;
  background-position: center left;
  background-attachment: fixed;
  background-repeat: no-repeat;
  z-index: 1;
}

.class_whuys {
  margin-bottom: 10rem;
}

@media (min-width: 1200px) {
  .class_whuys {
    margin-bottom: 15rem;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .class_whuys {
    margin-bottom: 10rem;
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .class_whuys {
    margin-bottom: 50rem;
  }
}

@media (max-width: 575px) {
  .class_whuys {
    margin-bottom: 35rem;
  }
}
@media (max-width: 540px) {
  .class_whuys {
    margin-bottom: 37rem;
  }
}
@media (max-width: 500px) {
  .class_whuys {
    margin-bottom: 40rem;
  }
}
@media (max-width: 470px) {
  .class_whuys {
    margin-bottom: 42rem;
  }
}
@media (max-width: 450px) {
  .class_whuys {
    margin-bottom: 45rem;
  }
}
@media (max-width: 425px) {
  .class_whuys {
    margin-bottom: 60rem;
  }
}
@media (max-width: 400px) {
  .class_whuys {
    margin-bottom: 70rem;
  }
}
@media (max-width: 380px) {
  .class_whuys {
    margin-bottom: 80rem;
  }
}