.carousel-container {
  padding: 0;
  margin: 0 auto;
}

.carousel-image {
  object-fit: cover;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.carousel-caption h3 {
  color: rgb(255, 255, 255);
  font-size: 3rem;
  font-family: Arial, sans-serif;
  white-space: nowrap;
  position: absolute;
  top: -4rem;
  left: 49%;
  transform: translate(-50%, -50%);
  text-align: center;
  animation: fadeIn 2s ease-in-out;
  animation-fill-mode: forwards;
  text-shadow: 2vw 2vw 5vw rgba(28, 99, 157, 1);
}

@media (max-width: 768px) {
  .carousel-caption h3 {
    font-size: 1rem;
    top: -2rem;
  }
}
.carousel-indicators button{
  background-color: rgb(29,104,166)!important;
  margin-bottom: 3vh;
  }
  @media (max-width: 768px) {
    .carousel-container .carousel-item img,
    .carousel-container .carousel-item video {
      object-fit: contain !important;
    }
  }
  